@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 1rem;
}
::-webkit-scrollbar-track {
  background: black;
}
::-webkit-scrollbar-thumb {
  background: yellow;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  font-size: 62.5%;
}

@media (max-width: 1200px) {
  html {
    font-size: 56.25%;
  }
}

@media (max-width: 900px) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 37.5%;
  }
}

@media (min-width: 2200px) {
  html {
    font-size: 75%;
  }
}
